import React from "react";
import { graphql, navigate } from "gatsby";
import { useDispatch } from "react-redux";

import Link from "../../components/Global/Link";
import Layout from "../../components/Templates/Layout";
import Meta from "../../utils/Meta";

import Hero from "../../components/Templates/Hero";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { contactActions } from "../../store/contact";

// Markup
const ContactExistingPage = (props) => {
  const { data, blok } = props;

  const dispatch = useDispatch();

  let doc;
  if (data) {
    doc = JSON.parse(data?.contact?.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc.title,
    description: doc.body,
  };

  const isPurchasingHandler = () => {
    dispatch(contactActions.setIsPurchasingMachines(true));
    dispatch(contactActions.setIsPurchasingPrizes(true));
    navigate("/contact/form");
  };

  const isTalkingHandler = () => {
    dispatch(contactActions.setIsTalkingManagement(true));
    navigate("/contact/form");
  };

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />
      <Hero
        title={doc.title}
        background={doc.image?.filename}
        colour={doc.colour && doc.colour}
      />
      <section className="w-100 py-5 brand-background-white brand-text-black brand-background-clip border">
        <div className="container py-5">
          <div className="row mb-5">
            <div className="col-12">
              <h1 className="brand-font text-uppercase text-center brand-text-purple">
                How can we help?
              </h1>
            </div>
          </div>
          <div className="row justify-content-center gy-4">
            <div className="col col-12 col-md-6 col-lg-4">
              <div className="d-flex flex-column brand-border-pink brand-border-radius overflow-hidden h-100 py-5">
                <div className="text-center mb-5">
                  <FontAwesomeIcon icon="basket-shopping" className="fs-1" />
                </div>
                <div className="d-flex flex-column h-100 px-4 text-center">
                  <h5 className="mb-5">I'd like to purchase more products</h5>
                  <div className="mt-auto">
                    <Link
                      button
                      colour="pink"
                      icon
                      onClick={isPurchasingHandler}
                    >
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-md-6 col-lg-4">
              <div className="d-flex flex-column brand-border-pink brand-border-radius overflow-hidden h-100 py-5">
                <div className="text-center mb-5">
                  <FontAwesomeIcon icon="circle-info" className="fs-1" />
                </div>
                <div className="d-flex flex-column h-100 px-4 text-center">
                  <h5 className="mb-5">
                    I need some help or support with my existing products
                  </h5>
                  <div className="mt-auto">
                    <Link button colour="pink" icon onClick={isTalkingHandler}>
                      Next
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactExistingPage;

export const QUERY = graphql`
  query ContactExistingPageQuery {
    contact: storyblokEntry(slug: { eq: "contact" }) {
      content
    }
    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;
